import React from 'react';
import AppContainerView from '../../components/AppContainerView';
import MobileTemplate from '../../components/MobileTemplate';

const productResultIndex = () => {
  return (
    <AppContainerView>
      <MobileTemplate></MobileTemplate>
    </AppContainerView>
  );
};
export default productResultIndex;
